<template>
  <BaseFilterDialogAndChips
    :submitted-values="submittedValues"
    :show-reset-button="showResetButton"
    name="is-project-point"
    @open="copyFilterParams"
    @clear:filter="clearFilter"
    @reset="resetFilters"
    @submit="submitFilters"
  >
    <v-row dense>
      <v-col cols="12">
        <v-select
          v-model="params.sprint_id"
          :items="sprints"
          :label="$t('general.sprint')"
          :loading="loading[`get:api/project-sprints`]"
          item-text="sprint_name"
          item-value="id"
          clearable
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="params.period_start"
          :label="$t('general.date_and_time.from')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput v-model="params.period_end" :label="$t('general.date_and_time.to')" />
      </v-col>
    </v-row>
  </BaseFilterDialogAndChips>
</template>

<script>
import BaseFilterDialogAndChips from '@/components/base/BaseFilterDialogAndChips';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import filterMixin from '@/mixins/filter-mixin';
import { mapGetters } from 'vuex';
import { getDefaultProjectPointStatisticsFilterParams } from '@/store/modules/project-point-statistics-module';

export default {
  name: 'ProjectPointStatisticsFilter',

  components: { BaseDatepickerInput, BaseFilterDialogAndChips },

  mixins: [filterMixin],

  props: {
    sprints: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      defaultParams: getDefaultProjectPointStatisticsFilterParams(),
    };
  },

  computed: {
    ...mapGetters(['loading']),

    submittedValues() {
      const values = [];
      const { sprint_id, period_start, period_end } = this.filterParams;

      if (sprint_id) {
        const sprint = this.sprints.find((s) => s.id === sprint_id);
        values.push({
          key: 'sprint_id',
          value: sprint?.sprint_name,
          label: this.$t('general.sprint'),
        });
      }

      if (period_start) {
        values.push({
          key: 'period_start',
          value: period_start,
          label: this.$t('general.date_and_time.from'),
        });
      }

      if (period_end) {
        values.push({
          key: 'period_end',
          value: period_end,
          label: this.$t('general.date_and_time.to'),
        });
      }

      return values;
    },
  },
};
</script>

<style scoped></style>
