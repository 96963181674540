<template>
  <div>
    <div class="d-flex align-center justify-end px-4">
      <ProjectPointStatisticsFilter
        :filter-params="projectPointStatisticsFilterParams"
        :sprints="sprints"
        @change="$router.push({ name: 'projectPointStatistics', query: $event })"
        @reset="resetProjectPointStatisticsFilters"
      />
    </div>

    <div class="pa-3 text-center">
      <v-progress-circular v-if="!showChart" color="primary" indeterminate />
      <HorizontalBarChart
        v-else
        :chart-data="chartData"
        :class="{ 'content-loading': loading['get:api/project-user-stories/statistics'] }"
      />
    </div>
  </div>
</template>

<script>
import ProjectPointStatisticsFilter from '@/components/filters/ProjectPointStatisticsFilter';
import { mapActions, mapGetters, mapState } from 'vuex';
import { getDefaultProjectPointStatisticsFilterParams } from '@/store/modules/project-point-statistics-module';
import { getSanitizedFilterParams } from '@/util/filter-params';
import HorizontalBarChart from '@/charts/HorizontalBarChart';
import projectSprintService from '@/api/project-sprint-service';

export default {
  name: 'ProjectPointStatistics',

  components: { HorizontalBarChart, ProjectPointStatisticsFilter },

  props: {
    projectId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      showChart: false,
      sprints: [],
    };
  },

  computed: {
    ...mapState('projectPointStatistics', [
      'projectPointStatistics',
      'projectPointStatisticsFilterParams',
    ]),
    ...mapGetters(['loading']),
    ...mapGetters('projectUserStories', ['projectUserStoryPointTypes']),

    chartData() {
      const userPointsMap = {};
      for (let i = 0; i < this.projectPointStatistics.length; i++) {
        const sprint = this.projectPointStatistics[i];
        for (let j = 0; j < sprint.assignees.length; j++) {
          const user = sprint.assignees[j];
          if (!userPointsMap[user.user_id]) {
            userPointsMap[user.user_id] = {
              full_name: user.full_name,
            };
            for (let k = 0; k < this.projectUserStoryPointTypes.length; k++) {
              const { completedPointsFieldKey } = this.projectUserStoryPointTypes[k];
              userPointsMap[user.user_id][completedPointsFieldKey] = 0;
            }
          }

          for (let k = 0; k < this.projectUserStoryPointTypes.length; k++) {
            const { completedPointsFieldKey } = this.projectUserStoryPointTypes[k];
            userPointsMap[user.user_id][completedPointsFieldKey] += user[completedPointsFieldKey];
          }
        }
      }

      // Remove users that haven't closed any points
      Object.keys(userPointsMap).forEach((key) => {
        let hasClosedPoints = false;
        for (let i = 0; i < this.projectUserStoryPointTypes.length; i++) {
          const { completedPointsFieldKey } = this.projectUserStoryPointTypes[i];
          if (userPointsMap[key][completedPointsFieldKey]) {
            hasClosedPoints = true;
            break;
          }
        }

        if (!hasClosedPoints) {
          delete userPointsMap[key];
        }
      });

      return {
        labels: Object.values(userPointsMap).map((user) => user.full_name),
        datasets: this.projectUserStoryPointTypes.map((points) => ({
          label: points.label,
          backgroundColor: this.$vuetify.theme.dark
            ? points.darkThemeColor
            : points.lightThemeColor,
          data: Object.values(userPointsMap).map((user) => user[points.completedPointsFieldKey]),
        })),
      };
    },
  },

  async created() {
    if (this.projectPointStatistics.length) {
      await this.$nextTick();
      this.showChart = true;
    }
    this.fetchProjectSprints();
    await this.fetchProjectPointStatistics(getSanitizedFilterParams(this.$route.query));
    this.showChart = true;
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.projectPointStatisticsFilterParams) !== JSON.stringify(params) &&
      to.name === 'projectPointStatistics'
    ) {
      this.fetchProjectPointStatistics(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('projectPointStatistics', ['fetchProjectPointStatistics']),

    async fetchProjectSprints() {
      const { data } = await projectSprintService.getAll({ project_id: this.projectId });
      this.sprints = data;
    },

    resetProjectPointStatisticsFilters() {
      const defaultFilters = getDefaultProjectPointStatisticsFilterParams();
      if (
        JSON.stringify(defaultFilters) === JSON.stringify(this.projectPointStatisticsFilterParams)
      ) {
        return;
      }
      this.$router.push({ name: 'projectPointStatistics', query: defaultFilters });
    },
  },
};
</script>

<style scoped>
.project-points-chart-wrapper {
  max-height: 700px;
  height: 100%;
}
</style>
